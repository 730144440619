import { render, staticRenderFns } from "./NotesTable.vue?vue&type=template&id=f5572794&scoped=true&"
import script from "./NotesTable.vue?vue&type=script&lang=js&"
export * from "./NotesTable.vue?vue&type=script&lang=js&"
import style0 from "./NotesTable.vue?vue&type=style&index=0&id=f5572794&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5572794",
  null
  
)

export default component.exports